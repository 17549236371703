@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap');
*{
    font-family: "Rubik",sans-serif;
}

.c-underline {
    position: relative;
    display: inline-block;
    font-weight: bold;
    transition: all 0.3s ease-in-out;
}

.c-underline::before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;
    width: 0;
    height: 2px;
    background-color: #ffffff;
    transition: width 0.3s ease-in-out;
}

.c-underline:hover::before {
    width: 100%;
}

.swiper {
    width: 250px;
    height: 450px;
}

.swiper-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 18px;
    font-size: 22px;
    font-weight: bold;
    color: #000000;
}

.swiper-slide:nth-child(1n) {
    background-color: rgb(206, 17, 17);
}

.swiper-slide:nth-child(2n) {
    background-color: rgb(0, 140, 255);
}

.swiper-slide:nth-child(3n) {
    background-color: rgb(10, 184, 111);
}

.swiper-slide:nth-child(4n) {
    background-color: rgb(211, 122, 7);
}

.swiper-slide:nth-child(5n) {
    background-color: rgb(118, 163, 12);
}

.swiper-slide:nth-child(6n) {
    background-color: rgb(180, 10, 47);
}

.swiper-slide:nth-child(7n) {
    background-color: rgb(35, 99, 19);
}

.swiper-slide:nth-child(8n) {
    background-color: rgb(0, 68, 255);
}

.swiper-slide:nth-child(9n) {
    background-color: rgb(218, 12, 218);
}

.swiper-slide:nth-child(10n) {
    background-color: rgb(54, 94, 77);
}
.banner-bg{
    background: rgb(0, 0, 0);
    background: linear-gradient(27deg, rgb(202, 249, 117) 26%, rgb(147, 233, 132) 82%);
    background-blend-mode: overlay;
}
.mySwiper{
    height: 100%;
}
.intro-slider{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}


/*Animaiton */


/* Horizontal Animation */
@keyframes h-slide {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(10px); /* Adjust the distance for half of the animation */
    }
    100% {
        transform: translateX(0);
    }
}

.h-animation {
    animation: h-slide 2s linear infinite; /* Adjust duration and timing function */
}

/* Vertical Animation */
@keyframes v-slide {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(10px); /* Adjust the distance for half of the animation */
    }
    100% {
        transform: translateY(0);
    }
}

.v-animation {
    animation: v-slide 2s linear infinite; /* Adjust duration and timing function */
}


@keyframes zoom {
    0% {
        transform: scale(1); /* Initial scale */
    }
    50% {
        transform: scale(1.05); /* Zoom in to 120% */
    }
    100% {
        transform: scale(1); /* Return to original scale */
    }
}

.zoom-effect {
    animation: zoom 5s ease-in-out infinite; /* Animation duration: 5 seconds */
}
.rotate-image {
    animation: rotate 5s linear infinite alternate; /* 2 seconds for each half rotation */
}

@keyframes rotate {
    from {
        transform: rotate(-20deg); /* Rotate 10 degrees left */
    }
    to {
        transform: rotate(20deg); /* Rotate 10 degrees right */
    }
}

.dotted-line {
    width: 100%; /* Adjust the width as needed */
    height: 2px; /* Adjust the height as needed */
    background-color: transparent; /* Set the background color to transparent */
    border-bottom: 4px dotted #450073; /* Add the dotted border line */
    animation: animateLine 5s linear forwards infinite; /* Animation settings */
}

@keyframes animateLine {
    0% {
        width: 0; /* Start with zero width */
    }
    100% {
        width: 40%; /* End with full width */
    }
}
.scale-ani {
    transition: transform 0.3s ease; /* Smooth transition */
}

.scale-ani:hover {
    transform: scale(1.05); /* Scale up by 10% on hover */
}

/* SplashScreen.css */
.splash-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #02bf74;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.splash-screen h1 {
    font-size: 24px;
    color: #333333;
}
/* Hide next and prev arrows by default */
.slick-next:before,
.slick-prev:before {
    display: block;
    transform: scale(2);
}

/* Media query for desktop view */
@media (min-width: 1024px) {
    .slick-dots li.slick-active button:before{
        display: none;
    }
    .slick-next:before,
    .slick-prev:before {
        display: none; /* Hide next and prev arrows in desktop view */
    }
}
.mySwiper .swiper-slide {
   border-radius: 40px;
    background-color: #000000;
}
.f-style{
    border-radius: 40px 0 0 0;
}
@keyframes fadeColor {
    0% {
        background-color: #450073; /* First color */
    }
    50% {
        background-color: #01be74; /* Second color */
    }
    100% {
        background-color: #450073; /* Back to first color */
    }
}
/* Applying the animation to the container */
.color-change {
    animation: fadeColor 6s infinite; /* 3 seconds for each color */
    transition: background-color 5s, opacity 5s; /* Smooth transition */
}


.image-container {
    position: relative;
}

.main-image {
    opacity: 1;
    transition: opacity 0.5s ease-in-out;
}

.hover-image {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease-in-out;
}

/* When hovering over the image container */
.image-container:hover .main-image {
    opacity: 0;
}

.image-container:hover .hover-image {
    opacity: 1;
}
.slick-prev:before, .slick-next:before {
    font-family: 'slick';
    font-size: 20px;
    line-height: 1;
    opacity: .75;
    color: #000000;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.poppins-thin {
    font-family: "Poppins", serif;
    font-weight: 100;
    font-style: normal;
}

.poppins-extralight {
    font-family: "Poppins", serif;
    font-weight: 200;
    font-style: normal;
}

.poppins-light {
    font-family: "Poppins", serif;
    font-weight: 300;
    font-style: normal;
}

.poppins-regular {
    font-family: "Poppins", serif;
    font-weight: 400;
    font-style: normal;
}

.poppins-medium {
    font-family: "Poppins", serif;
    font-weight: 500;
    font-style: normal;
}

.poppins-semibold {
    font-family: "Poppins", serif;
    font-weight: 600;
    font-style: normal;
}

.poppins-bold {
    font-family: "Poppins", serif;
    font-weight: 700;
    font-style: normal;
}

.poppins-extrabold {
    font-family: "Poppins", serif;
    font-weight: 800;
    font-style: normal;
}

.whitespace-pre-wrap {
    white-space: pre-wrap; /* Maintains whitespace formatting including line breaks */
}
